import React from 'react';
import './Experience.css';
import { themeContext } from '../../Context';
import { useContext } from 'react';
import { motion } from 'framer-motion';

const Experience = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  return (
   <div className="experience" id='Experience'>

       <motion.div
                   initial={{ rotate: 195}}
                   whileInView={{ rotate: 0}}
                   viewport={{margin: '40px'}}
                   transition={{duration: 3.5, type: 'spring'}}
       className="achievement">
           <div className="circle" >8+</div>
           <span>years</span>
           <span>Experience</span>
       </motion.div>

       <motion.div
                   initial={{ rotate: 145}}
                   whileInView={{ rotate: 0}}
                   viewport={{margin: '40px'}}
                   transition={{duration: 3.5, type: 'spring'}}
       className="achievement">
           <div className="circle">20+</div>
           <span>completed</span>
           <span>Projects</span>
       </motion.div>

       <motion.div
                   initial={{ rotate: 95}}
                   whileInView={{ rotate: 0}}
                   viewport={{margin: '40px'}}
                   transition={{duration: 3.5, type: 'spring'}}
       className="achievement">
           <div className="circle">5+</div>
           <span>companies</span>
           <span>tasks</span>
       </motion.div>

   </div>
  )
}

export default Experience;


