import React, { useState } from 'react';
import './Contact.css';
import emailjs from "@emailjs/browser";
import { useRef } from 'react';
import { themeContext } from '../../Context';
import { useContext } from 'react';

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const form = useRef();

  const [done, setDone] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_pbn9kp9', 'template_3e830qs', form.current, 'Klk2jP8UHYcJLKXJw')
      .then((result) => {
          console.log(result.text);
          setDone(true);
          setTimeout(() => {
            e.target.reset();
            setDone(false)
          }, 2000)
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <div className="contact-form">
        <div className="w-left">
            <div className="awesome">
                <span  style={{ color: darkMode ? 'white' : ''}}>Get In Touch</span>
                <span >Contact Me</span>
                <div className="blur s-blur"
                style={{ background: "#ABF1FF94"}}
                >
                </div>
            </div>
        </div>
        <div className="c-right">
        <form ref={form} onSubmit={sendEmail}>

          <input type="text" name="user_name" className="user"  placeholder="Name"/>
          <input type="email" name="user_email" className="user" placeholder="Email"/>
          <textarea name="message" className="user" placeholder="Send me a message"/>
          <input type="submit" value="Send" className="button"/>
          <span>{done && "Thanks for Contacting me"}</span>
          <div
            className="blur c-blur1"
            style={{ background: "var(--purple)" }}
            ></div>
        </form>
        </div>
    </div>
  )
}

export default Contact;