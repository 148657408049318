import React from 'react'
import Navbar from './components/Navbar/Navbar';
import './App.css';
import Intro from './components/intro/Intro';
import Services from './components/Services/Services';
import Experience from './components/Experience/Experience';
import Work from './components/Work/Work';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';
import Folder from './components/Folder/Folder';
import { themeContext } from './Context';
import { useContext } from 'react';


const App = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className='App'
    style={{
      background: darkMode ? 'black' : '',
      color: darkMode ? 'white' : ''
    }}
    >
      <Navbar />
      <Intro />
      <Services />
      <Experience />
      <Work />
      <Portfolio />
      <Contact />
      <Folder />
    </div>
  )
}

export default App;

