import React from 'react';
import './FlaotingDiv.css';

const FloatingDiv = ({ image, txtl1, txtl2 }) => {
  return (
   <div className="floatingdiv">
       <img src={image} alt="" />
       <span>
           {txtl1}
           <br />
           {txtl2}
       </span>
   </div>
  )
}

export default FloatingDiv; 