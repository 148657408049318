import React from 'react';
import './Services.css';
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from "../../img/humble.png";
import Card from '../Card/Card';
import Resume from '../../components/Services/Resume.pdf';
import { themeContext } from '../../Context';
import { useContext } from 'react';
import { motion } from 'framer-motion';

const Services = () => {
    const transition = { duration: 1, type: 'spring'};
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  return (
    <div className="services" id='Services'>

        {/* {left side} */}
        <div className="awesome"> 
            <span  style={{ color: darkMode ? 'white' : ''}}>My Awesome</span>
            <span>Services</span>
            <span>
                Lorem ispum is simply dummt text of printing of printing Lorem
                <br />
                ibl d3jlwebk jb    wjdhelw jo hf2el2k m uowe
            </span>
            <a href={Resume} download>
            <button className="button s-button"> Download CV</button>
            </a>
            <div className='blur s-blur' style={{ background: "#ABF1FF94"}}></div>
        </div>

        {/* {right side} */}
        <div className="cards">
            <motion.div
            whileInView={{ left: '14rem'}}
            initial={{ left: '25rem'}}
            transition={transition}
            style={{ left: "14rem"}}>
            <Card
            emoji={HeartEmoji}
            heading={"Design"}
            detail={"Css, Taiwind, Bootstrap, sass, ImportedStyles"}
            />
            </motion.div>
            {/* {second card} */}
            <div style={{ top: "12rem", left: "-4rem"}}>
                <Card
                emoji={Glasses}
                heading={"Developer"}
                detail={"Html, Css, Javscript, TypeScript, Redux, React"}
                />
            </div>
            {/* {3rd} */}
            <div style={{ top: "19rem", left: "12rem"}}>
                <Card 
                emoji={Humble}
                heading={"UI/UX"}
                detail={
                    "My name na lx agba idan fun awaon boys file fun oga nla agba one idan gan gan."
                }
                />
            </div>
            <div className="blur s-blur2" style={{ background: "var(--purple)"}}></div>
        </div>
    </div>
  )
}

export default Services;

